import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18next
    .use(XHR)
    .use(initReactI18next)
    .init({
        fallbackLng: 'th',
        ns: ['trans'],
        defaultNS: 'trans',
        debug: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        }
    })
