import React from 'react';
import './Home.css';
import Header from "../Header/Header";
import cover_tempo from "./images/cover_temporary.png";

const Home = () => {
    return (
        <div>
            <Header/>
            <img className="responsive-image" src={cover_tempo} alt="cover_tempo" />
        </div>
    );
}

export default Home;
