import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Header.css';
import lpb_bg_bk_logo from './images/lpb_bg_bk_logo.png';
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';

const Header = () => {
    const [click, setClick] = useState(false);
    const handleSideClick = () => setClick(!click);
    const closeMobileSideMenu = () => setClick(false);
    const onChangeLanguage = () => {
        i18n.language === 'th' ? i18n.changeLanguage('en') : i18n.changeLanguage('th');

    };
    const { t } = useTranslation();
    return (
        <>
            <nav className="navbar">
                <div className='navbar-container'>
                    <Link className="navbar-logo" end to="/" >
                        <img className="logo-image" src={lpb_bg_bk_logo} alt="lpb_bg_bk_logo" />
                        <div className="text-container">
                            <span className="top-text">Lamphun brewing</span>
                            <span className="bottom-text">ลำพูน บรูวิ่ง</span>
                        </div>
                    </Link>
                    <div className='menu-icon' onClick={handleSideClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'} >
                        <li className='nav-item' >
                            <Link to='/' className='nav-links' onClick={closeMobileSideMenu} >
                                {t('home')}
                            </Link>
                        </li>
                        <li className='nav-item' >
                            <Link to='/product' className='nav-links' onClick={closeMobileSideMenu} >
                                {t('product')}
                            </Link>
                        </li>
                        <li className='nav-item' >
                            <Link to='/somethingtoread' className='nav-links' onClick={closeMobileSideMenu} >
                                {t('somthingtoread')}
                            </Link>
                        </li>
                        <div className='nav-item'>
                            <div className='nav-links' onClick={onChangeLanguage} >
                                {i18n.language === 'th' ? 'ENGLISH' : 'ภาษาไทย'}
                            </div>
                        </div>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Header
