import React from 'react';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';

const NotFound = () => {
    return (
        <>
            <div>
                <h1>Page Not Found</h1>
                <Link to="/">Go to Home Page</Link>
            </div>
        </>
    )
}

const App = () => {
  return (
      <Router>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
  );
}

export default App;
